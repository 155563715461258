import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  Content,
  OutboundLink,
  StackSliderCarousel,
  componentIterator,
  Review,
  CustomerReviewModal,
  Iterator,
  StaffTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessLocation,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            className={"welcome-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "home_header",
                  defaultValue: "Home",
                })}
              />
              <Content className={"home-content"} textAlign={"left"}>
                <Content.Markup
                  width={8}
                  isMarkdown={true}
                  className={"right-with-ctas"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_description",
                  })}
                  extra={
                    <div className={"ctas-container"}>
                      <Button as={OutboundLink} to={bookingUrl.url}>
                        {bookingUrl.title}
                      </Button>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
          </Grid>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"main-content"}
          >
            <Grid.Column className={"reviews-column"}>
              <StackSliderCarousel
                size={1}
                displayIndicators={true}
                indicatorsPosition={"top"}
                autoScroll={false}
                animation={{ name: "blur", duration: 160 }}
                items={componentIterator({
                  iterator: fishermanBusiness.reviews,
                  component: <Review as={"horizontalItem"} charLimit={260} />,
                  propMap: { author: "author", text: "text" },
                })}
              />
              <div className={"submit-review-button-container"}>
                <CustomerReviewModal
                  businessId={fishermanBusiness._id}
                  locations={allFishermanBusinessLocation.nodes}
                  businessType={fishermanBusiness.type}
                />
              </div>
            </Grid.Column>
            <Grid.Column className={"images-column"}>
              <StackSliderCarousel
                size={3}
                stepSize={1}
                grouping={2}
                displayIndicators={false}
                autoScroll={false}
                invertedDirection={true}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: <Image className={"image"} />,
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"teams-container"}>
            <Header as={"h3"}>Artists</Header>
            <Card.Group
              className={"team-members"}
              itemsPerRow={4}
              doubling={true}
            >
              <Iterator
                iterator={allFishermanBusinessTeam.nodes.slice(0, 4)}
                key={"name"}
                component={<StaffTeamMember showMoreInfoButton={false} />}
                propMap={{ name: "name", role: "role", photo: "gatsbyImage" }}
              />
            </Card.Group>
            <Button to={"/team/"} as={InternalLink}>
              VIEW ALL ARTISTS
            </Button>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        author
        text
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
    allFishermanBusinessTeam(filter: { show: { eq: true } }) {
      nodes {
        _id
        name
        role
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        show
      }
    }
  }
`;
